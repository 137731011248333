import {createRouter, createWebHashHistory} from 'vue-router'
import HomeView from '../views/HomeView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView
  },
  {
    path: '/park',
    name: 'park',
    component: () => import('../views/ParkView.vue')
  },
  {
    path: '/news',
    name: 'news',
    component: () => import('../views/NewsView.vue')
  },
  {
    path: '/buyHotel',
    name: 'buyHotel',
    component: () => import('../views/BuyHotelView.vue')
  },
  {
    path: '/inter',
    name: 'inter',
    component: () => import('../views/InterView.vue')
  },
  {
    path: '/serve',
    name: 'serve',
    component: () => import('../views/ServeView.vue')
  },
  {
    path: '/hotel',
    name: 'hotel',
    component: () => import('../views/HotelView.vue')
  },
  {
    path: '/ticket',
    name: 'ticket',
    component: () => import('../views/TicketView.vue')
  },
  {
    path: '/buy',
    name: 'buy',
    component: () => import('../views/BuyView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/LoginView.vue')
  },
  {
    path: '/account',
    name: 'account',
    component: () => import('../views/AccountView.vue')
  },
  {
    path: '/accountMore',
    name: 'accountMore',
    component: () => import('../views/AccountMore.vue')
  },
  {
    path: '/accountTickets',
    name: 'accountTickets',
    component: () => import('../views/AccountTickets.vue')
  },
  {
    path: '/pay',
    name: 'pay',
    component: () => import('../views/PayView.vue')
  },
  {
    path: '/parkMore',
    name: 'parkMore',
    component: () => import('../views/ParkMoreView.vue')
  },
  {
    path: '/newsMore',
    name: 'newsMore',
    component: () => import('../views/NewsMoreView.vue')
  },
  {
    path: '/interMore',
    name: 'interMore',
    component: () => import('../views/InterMoreView.vue')
  },
  {
    path: '/payHotel',
    name: 'payHotel',
    component: () => import('../views/PayHotelView.vue')
  },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
