<template>
  <div class="home">
    <el-carousel v-if="data" height="40vw" width="100vw" autoplay loop :pause-on-hover="false" arrow="never"
                 indicator-position="none">
      <el-carousel-item v-for="item in data.info.bannerimages" :key="item">
        <img class="bannerimages" :src="item"/>
      </el-carousel-item>
    </el-carousel>
    <div class="play" v-show="step1"></div>

    <div class="play-buy" v-show="step1">
      <div class="btn-select">
        <div class="play-td">购买</div>
        <div class="play-select">
          <el-select placeholder="动物园" v-model="Type">
            <el-option label="动物园" value="1">
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="btn-select">
        <div class="play-td">票种</div>
        <div class="play-select">
          <el-select placeholder="请选择" v-model="buyType"  @change="playChanged">
            <el-option
                v-for="item in data1"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              <span style="float: left">{{ item.name }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.price + '' + item.unit }}</span>
            </el-option>
          </el-select>
        </div>
      </div>
      <div class="btn-select">
        <div class="play-td">票数</div>
        <div class="play-select">
          <el-input-number style="width: 100%" v-model="num" :min="1" :max="10" label="票数"></el-input-number>
        </div>
      </div>
      <div class="btn-select">
        <div class="play-td">游玩日期</div>
        <div class="play-select">
          <el-date-picker style="width: 100%"
                           v-if="isPicker"
                          v-model="date1"
                          :disabled-date="disableConfig"
                          value-format="YYYY-MM-DD"
                          format="YYYY 年 MM 月 DD 日"
                          type="date"
                          placeholder="选择日期">
          </el-date-picker>
        </div>
      </div>
      <div class="btn-select">
        <div class="goBuy" @click="goBuy">立即预订</div>
      </div>

    </div>
    <div class="tiger">
      <img style="" src="../assets/home/tiger-left.png"/>
      <img style="position: absolute;right: 0" src="../assets/home/tiger-right.png"/>
    </div>
    <div class="title" v-show="step2">园区介绍</div>
    <div class="content" v-show="step2" v-if="data">
      <div class="content-2 content-2-1">
        <video :src="data.introduction.videofile" controls></video>
      </div>
      <div class="content-2 content-2-2">
        <div class="text">{{ data.introduction.introduction }}</div>
      </div>
    </div>
    <div class="title" v-show="step3" style="margin-top: 20px;">园区地图</div>
    <div class="content content-1" v-show="step3" v-if="data">
      <el-image
          :src="data.info.zoomapimage"
          :preview-teleported="true"
          :preview-src-list="data.info.zoomapimage">
        <template #placeholder>
          <div class="image-slot">精彩加载中，请稍后<span class="dot">...</span></div>
        </template>
      </el-image>
      <div class="tips">
        <div class="tips-left"><img src="../assets/home/time.png"/>日常开放时间：{{ data.introduction.ticketintrducation }}
        </div>
        <div class="tips-right"><img src="../assets/home/player.png"/>园区最佳承载量：{{ data.introduction.carrying_capacity }}
        </div>
      </div>
    </div>
    <endTool></endTool>
  </div>
</template>

<script>
import endTool from "@/components/EndTool";
import axios from "axios";
import {apiHost} from "@/config.js";
let list = []
export default {
  name: 'HomeView',
  components: {
    endTool,
  },
  data() {
    return {
      data: null,
      currentScroll: 0,
      Type: '动物园',
      num: 0,
      buyType: '',
      date1: '',
      step1: false,
      step2: false,
      step3: false,
      step4: false,
      isPicker: true,
      data1: '',
      time: this.nowDate(),
      timeListA: null,
      buyInfo: [],
    }
  },
  methods: {
    playChanged(){
      let that = this
      that.timeListA = null
      that.isPicker = false
      const data = new FormData()
      data.append('id', that.buyType)
      axios({
        url: apiHost + '/api/ticket/ticketinfo',
        method: 'post',
        data: data,
      }).then(function (res) {
        that.buyInfo = res.data.data.info;
        var newTime = new Date()
        let timeList = eval('(' + that.buyInfo.otaticket.saleDateList + ')')
        if (timeList){
          for (let i = 0; i < timeList.length; i++) {
            const date = new Date(timeList[i].replace(/-/, "/"));
            if (newTime<=date) {
              list.push(that.timestampConversion(date.getTime()))
            }
          }
        }


        setTimeout(()=>{
          that.isPicker = true
        },)
      })
    },
    timestampConversion(time) {
      if (!time) {
        return;
      }
      let date;
      if (typeof time == 'number') {
        // 格式为时间戳
        date = new Date(parseInt(time));
      } else if (time.indexOf('Z') > -1 && time.indexOf('T') > -1) {
        // UTC格式
        date = new Date(time);
      }
      let year = date.getFullYear() + '.';
      let month = ( date.getMonth() + 1) + '.';
      let day = date.getDate();
      return year + month +day;
    },
    disableConfig(time) {
      if (list.length<=0) {
        return time.getTime() < new Date().getTime() - 3600 * 1000 * 24
      }

      if (this.buyInfo.otaticket.saleType !== '1') {
        let timeList = eval('(' + this.buyInfo.otaticket.preSaleDate + ')');
        return (
            time.getTime() < new Date().getTime() - 3600 * 1000 * 24 * parseInt(timeList.minDays) ||
            time.getTime() > new Date().getTime() + 3600 * 1000 * 24 * parseInt(timeList.maxDays)
        )
      } else {
        let time1 = time.getFullYear() + '.' + (time.getMonth() + 1) + '.' + time.getDate();
        return !list.includes(time1);
      }
    },
    goBuy() {
      let ticket_more = {type: this.buyType, count: this.num, date: this.date1}
      localStorage.setItem('buy', JSON.stringify(ticket_more))
      this.$router.push({path: '/buy'});
    },
    sel() {
      let that = this
      if (that.data1 && that.data2) return
      axios({
        method: 'get',
        url: apiHost + '/api/ticket/tickettype',
      }).then(res => {
        that.data1 = res.data.data[1].list
      })
    },
    nowDate() {
      const date = new Date();
      const y = date.getFullYear()
      const m = date.getMonth() + 1
      const day = date.getDate()
      return y + '-' + m + '-' + day
    },
    buy() {
      this.$router.push({path: '/ticket'});
    },
    HomeHandleScroll() {
      let currentScroll = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset //表示当前滚动的位置
      if (currentScroll === 0) {
        this.step1 = false
        this.step2 = false
        this.step3 = false
        this.step4 = false
        this.step5 = false
        document.getElementsByClassName('headTool')[0].style.transform = 'translateY(0px)'
      }
      if (currentScroll >= 10) {
        this.step1 = true
      }
      if (currentScroll >= 168) {
        this.step2 = true
      }
      if (currentScroll >= 500) {
        this.step3 = true
      }
      if (currentScroll >= 679) {
        this.step4 = true
      }
    },
    initIndex() {
      let that = this
      axios({
        method: 'get',
        url: apiHost + '/api/index/home',
      }).then(res => {
        that.data = res.data.data
      })
    },
  },
  mounted() {
    this.initIndex()
    this.sel()
    window.addEventListener('scroll', this.HomeHandleScroll, true)
  },
  beforeDestroy() {
    window.removeEventListener('scroll', this.HomeHandleScroll, true)
  },
}
</script>
<style>
th, .el-date-table-cell__text {
  text-align: center;
}
</style>
<style scoped>
.image-slot {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background: var(--el-fill-color-light);
  color: var(--el-text-color-secondary);
  font-size: 30px;
}
.dot {
  animation: dot 2s infinite steps(3, start);
  overflow: hidden;
  font-size: 30px;
}
/*select{*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  text-align: center;*/
/*  border-radius: 15px;*/
/*}*/
.play-select {
  height: 30px;
}

.btn-select {
  display: inline-block;
  vertical-align: middle;
  width: 170px;
  margin: 0 25px;
}

.play-buy {
  padding: 40px 0;
  width: 1200px;
  height: 213px;
  left: 0;
  right: 0;
  margin: -213px auto 0 auto;
  text-align: center;
  position: absolute;
  z-index: 3;
  /*animation: 0.5s 0.5s play-buy both;*/
}

@keyframes play-buy {
  from {
    opacity: 0
  }
  to {
    opacity: 1
  }
}

.play-td {
  height: 33px;
  font-size: 24px;
  font-family: PingFang SC-Bold, PingFang SC;
  font-weight: bold;
  color: #1A1311;
  line-height: 33px;
  text-align: center;
  margin-bottom: 10px;
}

.goBuy {
  width: 199px;
  height: 51px;
  margin: 0 auto 0 auto;
  background: #E56D1A no-repeat 2vw center/1.8vw;
  border-radius: 5px;
  font-size: 19px;
  font-family: SourceHanSansCN-Medium-, SourceHanSansCN-Medium;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 51px;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 10px 1px #888;
}

td {
  text-align: center;
}

.play-button {
  width: 80%;
  margin: 0 auto;
  height: 31px;
  background: #E8EEE6;
  border-radius: 3px 3px 3px 3px;
  opacity: 1;
  border: 1px solid #1A1311;
  line-height: 31px;
  text-align: center;
  font-size: 18px;
}

.tiger {
  height: 1px;
  width: 90vw;
  margin: 0 auto;
}

.tips-left {
  float: left;
}

.tips-left > img, .tips-right > img {
  vertical-align: middle;
}

.tips-right {
  float: right;
}

.tips {
  height: 50px;
  font-size: 22px;

  font-weight: 400;
  color: #1D5B2D;
  line-height: 50px;
  width: 90%;
  margin: 0 auto;
}

.el-image > img,.el-image {
  width: 90%;
  margin: 0 auto;
  height: 90%;
}

.text {
  height: 100%;
  width: 100%;
  padding: 30px;
  font-size: 1vw;

  font-weight: 400;
  color: #080505;
  line-height: 2.25vw;
  text-indent: 32px;
}

video {
  padding: 15px;
  height: 100%;
  width: 100%;
  object-fit: fill;
  border-radius: 12px;
}

.content-2 {
  float: left;
  width: 48%;
  height: 356px;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0 0 15px 1px rgba(75, 77, 75, 0.76);
}

.content-2-1 {
  animation: fade-in-left 1s ease .5s 1 both;

}

.content-2-2 {
  animation: fade-in-right 1s ease .5s 1 both;

}

@keyframes fade-in-right {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

@keyframes fade-in-left {
  0% {
    transform: translateX(-50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.content > .content-2:nth-child(1) {
  margin-right: 4%;
}

.content {
  margin: 0 auto;
  width: 70vw;
  height: 400px;
}

.content-1 {
  padding-top: 20px;
  text-align: center;
  height: 680px;
  border-radius: 12px;
  background: #FFFFFF;
  box-shadow: 0 0 15px 1px rgba(75, 77, 75, 0.76);
  margin-bottom: 20px;
  animation: fade-in-bottom 1s ease 0.5s 1 normal both;
}

.title {
  margin: 99px auto 30px;
  width: 252px;
  height: 60px;
  background: #538733;
  border-radius: 8px;
  font-size: 34px;

  font-weight: 400;
  color: #FFFFFF;
  line-height: 60px;
  text-align: center;
  animation: fade-in-bottom 1s ease 0s 1 normal forwards;
}

.el-carousel {
  overflow: hidden;
}

.play {
  padding: 30px 56px;
  background: rgb(232 238 230 / 70%);
  width: 1200px;
  height: 213px;
  z-index: 0;
  left: 0;
  right: 0;
  margin: -213px auto 0 auto;
  border-radius: 20px 20px 7px 7px;
  /*background-image: radial-gradient(transparent 3px,#fff 1px);*/
  background-size: 4px 4px;
  backdrop-filter: saturate(50%) blur(4px);
  animation: fade-in 1s ease 0s 1 normal forwards;
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-in-bottom {
  0% {
    transform: translateY(50px);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

.el-carousel::after {
  content: "";
  position: absolute;
  bottom: -2px;
  width: 100vw;
  height: 224px;
  z-index: 1;
  background: url("../assets/home/bannerBG.png") no-repeat center bottom / 100%;
}

.bannerimages {
  height: 100%;
  width: 100%;
}

.home {
  width: 100vw;
  overflow: hidden;
}
</style>
