<template>
  <div class="headTool">
    <router-link :to="{name:'home'}">     <div class="menus" :class="item===1?'bg':''" @click="item=1">园区首页</div></router-link>
    <router-link :to="{name:'park'}">     <div class="menus" :class="item===2?'bg':''" @click="item=2">走进园区</div></router-link>
    <router-link :to="{name:'news'}">     <div class="menus" :class="item===3?'bg':''" @click="item=3">活动新闻</div></router-link>
    <router-link :to="{name:'inter'}">    <div class="menus" :class="item===4?'bg':''" @click="item=4">互动体验</div></router-link>
    <router-link :to="{name:'serve'}">    <div class="menus" :class="item===5?'bg':''" @click="item=5">游客服务</div></router-link>
<!--    <router-link :to="{name:'hotel'}">    <div class="menus" :class="item===6?'bg':''" @click="item=6">酒店预定</div></router-link>-->
    <router-link :to="{name:'ticket'}">   <div class="menus" :class="item===7?'bg':''" @click="item=7">门票年卡</div></router-link>
    <router-link :to="{name:'account'}">  <div class="menus" :class="item===8?'bg':''" @click="item=8">个人中心</div></router-link>
  </div>
  <keep-alive>
    <router-view ref="router"/>
  </keep-alive>
  <div v-if="btnFlag" ref="hello" class="hello">
    <div ref="qr" class="qr">
      <div class="qr_img qr_img2" @click="pay"></div>
      <div class="qr_text">门票订购</div>
      <div class="qr_img">
        <img class="gzh" src="./assets/home/gzh.jpg" />
      </div>
      <div class="qr_text">公众号</div>
      <div class="qr_img">
        <img class="wb" src="./assets/home/d.png" />
      </div>
      <div class="qr_text">抖音号</div>
      <router-link :to="{name:'inter',query: {type:2}}"> <div class="qr_img qr_img1"></div></router-link>
      <div class="qr_text">留言反馈</div>

      <div class="qr_end" @click="toTop">
        <!--          <img align="top" loading="lazy" src="./assets/home/top.png" style="margin: 0 auto;">-->
        TOP
      </div>
    </div>
    <div ref="hi" class="hi" @click="hi_close">
    </div>
  </div>
  <div class="leavMessage" v-if="mesFlag">
    <div class="mesBg" @click="mesFlag = false"></div>
    <div class="message">
      <div class="mContent">
        <div class="mClose" @click="mesFlag = false">X</div>
        <div class="mFirst">
          <div class="mTitle">留言反馈</div>
          <img alt="" class="rBz" src="./assets/home/ruleTitle.png">
        </div>
        <div class="mText">
          <span class="Text">若您有宝贵的意见或建议，可填写在以下方框内</span>
          <span class="Text">我们相信有您宝贵的意见和建议，在今后的工作中会不断完善!</span>
        </div>
        <div class="mSecond">
          <el-input v-model="name" :style="{marginRight:15+'px'}" class="el_foot" placeholder="请输入姓名"></el-input>
          <el-input v-model="mobile" class="el_foot" placeholder="请输入手机号码"></el-input>
        </div>
        <el-input
            v-model="message"
            :rows="6"
            class="el_foot"
            placeholder="请输入内容"
            type="textarea">
        </el-input>
        <div class="mThird">
          <span class="upTip1">上传图片</span>
          <span class="upTip2">（最多可传3张照片，每张不超过5M，仅支持JPG,PNG格式）</span>
        </div>
        <div class="sec-file-upload">
          <div
              v-for="(item, index) in files"
              :key="index"
              :style="{
                    backgroundImage: 'url('+item.content+')'
                }"
              class="item"
          >
            <div
                class="del"
                @click="removeImage(index)"
            >×
            </div>
          </div>
          <div
              v-show="files.length < filesLimit"
              class="item plus"
          >
            <input ref="fileinput" multiple type="file" @change="selectImage">
            <div class="icon">
              <div class="h"></div>
              <div class="v"></div>
            </div>
          </div>
          <canvas
              id="canvas"
              ref="canvas"
              height="1000"
              width="1000"
          ></canvas>
        </div>
        <div class="ly" @click.native="submitMessage">提交留言</div>
      </div>
    </div>
  </div>
</template>

<script>
import {apiHost} from "@/config";
import qs from "qs";
import axios from "axios";

export default {
  data(){
    return{
      item: 0,
      mesFlag: false,
      longSideWidth: 1000,
      btnFlag: false,
      name:'',
      mobile:'',
      message:'',
    }
  },
  watch:{
    $route(to,from){
      if (to.name==='home') this.item = 1
      if (to.name==='park') this.item = 2
      if (to.name==='news') this.item = 3
      if (to.name==='inter') this.item = 4
      if (to.name==='serve') this.item = 5
      if (to.name==='hotel') this.item = 6
      if (to.name==='ticket') this.item = 7
      if (to.name==='account') this.item = 8
    }
  },
  created() {
    let that = this
    if (that._isMobile()) {
      window.location.href="https://www.xzyszoo.com/h5/#/"
    }
  },
  methods:{
    _isMobile() {
      return navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i);
    },
    bah(){
      window.location='https://beian.miit.gov.cn/'
    },
    toTop() {
      window.scrollTo({top: 0, behavior: 'smooth'})
    },
    scrollToTop() {
      const that = this
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      that.scrollTop = scrollTop
      if (that.scrollTop > 600) {
        that.btnFlag = true
      } else {
        that.btnFlag = false
        that.mesFlag = false
        that.hFlag = false
      }
    },
    pay() {
      this.$router.push({path: '/ticket'})
    },
    hi_close() {
      if (!this.hFlag) {
        this.$refs.qr.style.opacity = '0'
        this.$refs.hello.style = 'height:0;width:0;'
        this.$refs.hi.style = 'height:50px;width:50px;'
        this.$refs.hi.style.borderRadius = '25px'
        this.$refs.hi.style.transform = 'rotate(-90deg)'
        this.$refs.hi.style.opacity = '0.4'
        this.hFlag = true
      } else {
        this.toTop();
        this.hFlag = false
      }
    },
    async submitMessage() {
      // 数据校验
      if (!this.validate()) return false
      // 图片上传
      this.$notify.info('正在上传')
      const that = this
      this.imageUpload(2, (payload) => {
        that.realSubmit(payload)
      })

    },
    validate() {
      const { name, mobile, message } = this
      if (!(name && mobile && message)) {
        this.$toastr.error('请填写完整信息')
        return false
      }
      if (!isPhoneNumber(mobile)) {
        this.$toastr.error('请填写正确的手机号码')
        return false
      }
      return true
    },
    async realSubmit(payload) {
      const { name, mobile, message } = this
      const that = this
      const res = await axios({
        url: apiHost + '/api/index/message',
        method: 'POST',
        data: qs.stringify({
          name,
          phone: mobile,
          content: message,
          uploadimage: payload.data
        })
      })
      if (res.status === 200 && res.data.code === 1) {
        that.$notify.success('我们已经收到您的留言，感谢您的反馈')
        // 清空内容
        that.name = ''
        that.mobile = ''
        that.message = ''
        that.files = []
        that.filesUploaded = []
      } else {
        that.$notify.error('提交失败,请重试')
      }
    },
    imageUploadCheck(retry, cb) {
      clearTimeout(this.checkTimer);
      var that = this;
      var hasError = false;
      var hasUnloading = false;
      for(let i = 0; i < this.files.length; i += 1) {
        var file = this.files[i];
        if(file.uploadStatus === 0) {
          hasUnloading = true
        }
        if(file.uploadStatus === 2) {
          hasError = true;
        }
      }
      if(hasError || hasUnloading) {
        this.checkTimer = setTimeout(function() {
          that.imageUploadCheck(retry, cb);
        }, 1000);
        return;
      }
      if(hasError && !hasUnloading) {
        // 上传过程结束，但是存在错误再次触发上传
        if(retry > 0) {
          this.imageUpload(retry--, cb);
        } else {
          if(typeof cb === 'function') {
            cb({
              status: 'fail',
              message: '文件上传失败',
            })
          }
        }
        return;
      }
      if(typeof cb === 'function') {
        var fileName = [];
        this.files.map(function(item) {
          fileName.push(item.newName)
        })
        cb({
          status: 'success',
          data: fileName.join(',')
        });
      }
    },
    imageUpload(retry, cb) {
      var that = this;
      this.imageUploadCheck(retry, cb);
      for(var i = 0; i < this.files.length; i += 1) {
        if(this.files[i].uploadStatus === 1) {
          // 已完成项
          continue;
        }
        var imageData = this.imageCompress(this.files[i]);
        (function(index, data){
          var formData = new FormData();
          formData.append('file', data, 'canvasimage.jpg');
          that.$tloi_request.post(apiHost + '/api/common/upload', formData)
              .then((res) => {
                if(res) {
                  that.files[index].uploadStatus = 1;
                  that.files[index].newName = res.url;
                } else {
                  that.files[index].uploadStatus = 2;
                }
              }).catch((error) => {
            that.files[index].uploadStatus = 2;

          })
        })(i, imageData)
      }
    },
    imageCompress(imageContent) {
      var image = new Image();
      image.src = imageContent.content;
      var imageWidth = image.width;
      var imageHeight = image.height;
      var newWidth, newHeight;
      if(imageWidth > imageHeight) {
        // 宽为长边
        newWidth = this.longSideWidth;
        newHeight = this.longSideWidth * imageHeight / imageWidth;
      } else {
        // 高为长边
        newHeight = this.longSideWidth;
        newWidth = this.longSideWidth * imageWidth / imageHeight;
      }
      var imageCanvas = this.$refs.canvas;
      var context = imageCanvas.getContext('2d')
      imageCanvas.width = newWidth
      imageCanvas.height = newHeight
      context.clearRect(0, 0, newWidth, newHeight);
      context.drawImage(image, 0, 0, newWidth, newHeight)
      var dataUrl = imageCanvas.toDataURL('image/jpeg', 0.8);
      return convertBase64UrlToBlob(dataUrl, 'image/jpeg');
    },
    removeImage(index) {
      this.files.splice(index, 1)
    },
    selectImage() {
      const that = this
      const files = this.$refs.fileinput.files;
      if(!files) {
        return
      }
      if((that.files.length + files.length) > this.filesLimit) {
        that.$notify.error(`最多只能选用${this.filesLimit}张图片， 仅支持png, jpg, jpeg格式`)
        return
      }
      if(files) {
        for(var i = 0; i < files.length; i += 1) {
          (function(index) {
            var file = files[index]
            var fileFormat = file.name.substring(file.name.lastIndexOf(".")).toLowerCase();
            if( !fileFormat.match(/.png|.jpg|.jpeg/) ) {
              that.$notify.error('文件格式必须为：png/jpg/jpeg');
              return;
            }
            var repeat = false;
            for(var j = 0; j < that.files.length; j += 1) {
              if(file.name === that.files[j].name) {
                repeat = true
              }

            }
            if(repeat) {
              that.$notify.error('请不要上传重复的图片！')
              return
            }
            var fileReader = new FileReader();
            fileReader.readAsDataURL(file);
            fileReader.onload = function(e) {
              that.files.push({
                origin: file,
                content:  e.target.result,
                uploadStatus: 0, // 0：未处理，1：成功，2：失败
                newName: null
              });
              that.$forceUpdate()
            }
          })(i)
        }
      }
      // 清空fileinput
    },
    scrollFunc : function (e) {
      let scrollTop1 = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop1 >= 50) {
        e = e || window.event;
        if (e.wheelDelta) {//IE/Opera/Chrome
          if (e.wheelDelta > 0) {
            document.getElementsByClassName('headTool')[0].style.transform = 'translateY(0px)'
          }
          if (e.wheelDelta < 0) {
            document.getElementsByClassName('headTool')[0].style.transform = 'translateY(-12vh)'
          }
        } else if (e.detail) {//Firefox
          if (e.detail < 0) {
            document.getElementsByClassName('headTool')[0].style.transform = 'translateY(0px)'
          }
          if (e.detail > 0) {
            document.getElementsByClassName('headTool')[0].style.transform = 'translateY(-12vh)'
          }
        }
      }
    },
    // scrollToHead() {
    //   const that = this
    //   let scrollTop1 = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
    //   that.scrollTop1 = scrollTop1
    //   if (that.scrollTop1 > 5) {
    //     that.isHead = false
    //   } else {
    //     that.isHead = true
    //   }
    // },
  },
  mounted() {
    // window.addEventListener(' ', this.scrollToHead)
    window.addEventListener('scroll', this.scrollToTop)
    window.onmousewheel = document.onmousewheel = this.scrollFunc;
  },
  beforeDestroy() {
    // window.removeEventListener('scroll', this.scrollToHead)
    window.removeEventListener('scroll', this.scrollToTop)
    window.removeEventListener('DOMMouseScroll', this.scrollFunc)
  },
}
</script>
<style scoped>

.hi {
  cursor: pointer;
  transition-delay: 1s;
  transition: all 1s;
  width: 21px;
  height: 68px;
  position: absolute;
  background: rgb(73 142 50) url("./assets/home/left.png") no-repeat center;
  left: -21px;
  bottom: 150px;
  border-radius: 5px 0 0 5px;
}
.hi:hover{
  opacity: 1!important;
}
.qr_end {
  box-sizing: border-box;
  padding: 17px;
  text-align: center;
  position: relative;
  bottom: -26px;
  height: 43px;
  width: 91%;
  margin-left: 3%;
  cursor: pointer;
  color: #FFFFFF;
  border-radius: 0px 0px 10px 10px;
  background: rgb(73 142 50) url("./assets/home/top.png")no-repeat center 5px / 22px;
}

@keyframes fade-in-right {
  0% {
    transform: translateX(50px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}

.hello {
  animation: 1s fade-in-right both;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  position: fixed;
  z-index: 999;
  opacity: 1;
  right: 50px;
  bottom: 50px;
  height: 472px;
  width: 98px;
  text-align: center;
  font-size: 12px;
  color: #5E7959;
  transition: all 1s;
  background: url("./assets/home/qr_bg.png") no-repeat center / 100% 100%;
}

.qr_img1{
  cursor: pointer;
  background: url("./assets/home/message.png") no-repeat center / 100% 100%;
}
.qr_img2 {
  cursor: pointer;
  background: url("./assets/home/ticket.png") no-repeat center / 100% 100%;
}

.qr_img {
  width: 60px;
  height: 60px;
  margin: 15px auto 10px auto;

}
.gzh{
  width: 60px;
  opacity: 1;
  transition: all 0.2s linear !important;
  z-index: 2022;
  position: relative;
  top:0;
  left: 0;
  border-radius: 7px;
  box-shadow: 0 0 10px 1px #888;
}
.wb{
  width: 60px;
  opacity: 1;
  border-radius: 7px;
  transition: all 0.2s linear !important;
  z-index: 2022;
  position: relative;
  top:0;
  left: 0;
}
.gzh:hover{
  width: 130px;
  opacity: 1;
  top:-40px;
  left:-40px;
  box-shadow: 0 0 20px 2px #444;
}
.wb:hover{
  width: 130px;
  opacity: 1;
  top:-40px;
  left:-40px;
  box-shadow: 0 0 10px 1px #444;
}
.headTool:first-child{
  padding-left: 20vw;
}
.menus:hover{
  background-position: center;
  cursor: pointer;
}
.menus{
  float: left;
  min-width: 8vw;
  height: 5vh;
  text-align: center;
  margin-right: 1.5vw;
  margin-top: 3.5vh;
  font-size: 18px;
  font-weight: 400;
  line-height: 5vh;
  color: #FFFFFF;
  background-image: url("./assets/tool/sel.png");
  background-position: 0 5vh;
  background-size: auto 100%;
  background-repeat: no-repeat;
  transition: all 0.3s ease;
}
.headTool{
  position: sticky;
  top: 0;
  transition: 0.5s all ease;
  z-index: 1024;
  height: 12vh;
  width: 100vw;

  background: #538733 url("./assets/tool/logo.png") no-repeat  5vw / 10vw ;
}
.bg{
  background-image: url("./assets/tool/sel.png");
  background-position: center;
}
.mClose{
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  cursor: pointer;
  text-align: center;
  line-height: 60px;
  position: absolute;
  right: -30px;
  top: -30px;
  font-size: 30px;
  z-index: 2022;
  width: 60px;
  height: 60px;
}
.mesBg{
  background: rgba(0, 0, 0, 0.66);
  position: fixed;
  z-index: 99;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
}
.leavMessage {
  position: fixed;
  z-index: 999;
  top: 25%;
  left: calc(50% - 240px);
}
.qr_text{
  text-align: center;
}
</style>
<style>
body{
  background: #E8EEE6;
}
body::-webkit-scrollbar {
  display: none;
}
*{
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: Noto Sans SC;
  text-align: justify;
}

</style>
