<template>
  <div class="endTool">
    <div class="bg"></div>
    <div class="menus">
      <table style="width: 70vw;margin: 256px auto 0 auto;text-align: center">
        <tr>
          <td class="head" @click.prevent="routerName(1)">走进园区</td>
          <td class="head" @click.prevent="routerName(2)">园区动态</td>
          <td class="head" @click.prevent="routerName(3)">游客服务</td>
          <td class="head" @click.prevent="routerName(4)">在线订票</td>
          <td class="head" @click.prevent="routerName(5)">个人中心</td>
        </tr>
        <tr>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr>
          <td class="mouse" @click.prevent="routerName(1)">园区特色</td>
          <td class="mouse" @click.prevent="routerName(2)">园区新增</td>
          <td class="mouse" @click.prevent="routerName(3)">游乐路线</td>
          <td class="mouse" @click.prevent="routerName(4)">门票预定</td>
          <td class="mouse" @click.prevent="routerName(5)">我的订单</td>
        </tr>
        <tr>
          <td class="mouse" @click.prevent="routerName(1)">园区介绍</td>
          <td class="mouse" @click.prevent="routerName(2)">园区动态</td>
          <td class="mouse" @click.prevent="routerName(3)">游玩指南</td>
          <td></td>
          <td class="mouse" @click.prevent="routerName(5)">个人资料</td>
        </tr>
        <tr>
          <td class="mouse" @click.prevent="routerName(1)">展区介绍</td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
      </table>
      <div class="tel">{{isHotel?'客房热线：0516-88155966':'园区热线：0516-88009888'}}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'endTool',
  data(){
    return{
      isHotel: false
    }
  },
  methods:{
    routerName(i){
      switch (i) {
        case 1:
          this.$router.push({name:'park'})
          break
        case 2:
          this.$router.push({name:'news'})
          break
        case 3:
          this.$router.push({name:'serve'})
          break
        case 4:
          this.$router.push({name:'ticket'})
          break
        case 5:
          this.$router.push({name:'account'})
          break

      }

    }
  },
  mounted() {
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.tel{
  width: 407px;
  height: 51px;
  margin: 2vw auto 0 auto;
  background: #E56D1A url("../assets/tel.png") no-repeat 2vw center  / 1.8vw;
  border-radius: 5px;
  font-size: 19px;
  font-family: SourceHanSansCN-Medium-, SourceHanSansCN-Medium;
  font-weight: normal;
  color: #FFFFFF;
  line-height: 51px;
  text-align: center;
  box-shadow: 0 0 10px 1px #888;
}
.mouse{
  cursor: pointer;
}
td{
  height: 33px;
  font-size: 0.9vw;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #747176;
  line-height: 1vw;
  text-align: center;
}
.head{
  height: 45px;
  font-size: 1.2vw;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #1A1311;
  line-height: 2vw;
  cursor: pointer;
}
.endTool{
  position: relative;
  height: 578px;
  width: 100vw;
  //background: url("../assets/tool/endTool.png") no-repeat center bottom / 100%;
}
.bg{
  position: absolute;
  z-index: -1;
  width: 100vw;
  height: 1202px;
  top: -610px;
  background: url("../assets/tool/endTool.png") no-repeat center bottom / 100%;
}
</style>
